import * as React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useForm } from "react-hook-form"
import "../stylesheets/contact.scss"

const ContactPage = () => {
  const { register, handleSubmit, errors } = useForm()
  const [isConfirm, setConfirm] = React.useState(false)
  const [formData, setFormData] = React.useState()
  const onSubmit = data => {
    setFormData(data)
    setConfirm(true)
  }

  const handleOnBack = () => {
    setConfirm(false)
  }

  return (
    <Layout>
      <SEO
        title="お問い合わせ"
        description="道新インタラクティブ株式会社のお問い合わせページです。弊社へのお問い合わせはこちらからお願いします。"
      />
      <div className="contactPage-MainVisual">
        <div className="contactPage-MainVisual-headingWrapper">
          <h1 className="contactPage-MainVisual-heading">お問い合わせ</h1>
          <p className="contactPage-MainVisual-subHeading">Contact</p>
        </div>
      </div>
      <section className="contactPage-MainSection">
        <div className="contactPage-MainSection-inner">
          <p className="contactPage-MainSection-text">
            お問い合わせ内容の確認後、担当者よりご連絡させていただきます。
            <br />
            なお、お問い合わせ内容によっては、お答えできない場合や回答に時間を要する場合があります。
            <br />
            あらかじめご了承ください。
          </p>
          {isConfirm ? (
            <ConfirmView {...formData} onBack={handleOnBack} />
          ) : (
            <form name="contact" onSubmit={handleSubmit(onSubmit)}>
              <div className="contactPage-MainSection-formRow">
                <label
                  className="contactPage-MainSection-formLabel"
                  htmlFor="name"
                >
                  お名前
                </label>
                <div className="contactPage-MainSection-formInputWrapper">
                  <input
                    type="text"
                    name="name"
                    ref={register({ required: true })}
                    defaultValue={formData?.name}
                    className="contactPage-MainSection-formInput"
                  />
                  {errors.name && (
                    <p className="contactPage-MainSection-errorMessage">
                      お名前は必須です。
                    </p>
                  )}
                </div>
              </div>
              <div className="contactPage-MainSection-formRow">
                <label
                  className="contactPage-MainSection-formLabel"
                  htmlFor="company"
                >
                  会社名（団体名）
                </label>
                <div className="contactPage-MainSection-formInputWrapper">
                  <input
                    type="text"
                    name="company"
                    ref={register({ required: true })}
                    defaultValue={formData?.company}
                    className="contactPage-MainSection-formInput"
                  />
                  {errors.company && (
                    <p className="contactPage-MainSection-errorMessage">
                      会社名（団体名）は必須です。
                    </p>
                  )}
                </div>
              </div>
              <div className="contactPage-MainSection-formRow">
                <label
                  className="contactPage-MainSection-formLabel"
                  htmlFor="email"
                >
                  メールアドレス
                </label>
                <div className="contactPage-MainSection-formInputWrapper">
                  <input
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                    defaultValue={formData?.email}
                    className="contactPage-MainSection-formInput"
                  />
                  {errors.email && (
                    <p className="contactPage-MainSection-errorMessage">
                      メールアドレスは必須です。
                    </p>
                  )}
                </div>
              </div>
              <div className="contactPage-MainSection-formRow">
                <label
                  className="contactPage-MainSection-formLabel"
                  htmlFor="tel"
                >
                  電話番号
                </label>
                <div className="contactPage-MainSection-formInputWrapper">
                  <input
                    type="number"
                    name="tel"
                    ref={register({ required: true })}
                    defaultValue={formData?.tel}
                    className="contactPage-MainSection-formInput"
                  />
                  {errors.tel && (
                    <p className="contactPage-MainSection-errorMessage">
                      電話番号は必須です。
                    </p>
                  )}
                </div>
              </div>
              <div className="contactPage-MainSection-formRow">
                <label
                  className="contactPage-MainSection-formLabel"
                  htmlFor="contents"
                >
                  お問い合わせ内容
                </label>
                <div className="contactPage-MainSection-formInputWrapper">
                  <textarea
                    name="contents"
                    rows="4"
                    ref={register({ required: true })}
                    defaultValue={formData?.contents}
                    className="contactPage-MainSection-formInput"
                  />
                  {errors.contents && (
                    <p className="contactPage-MainSection-errorMessage">
                      お問い合わせ内容は必須です。
                    </p>
                  )}
                </div>
              </div>
              <div className="contactPage-MainSection-privacypolicyWrapper">
                <p className="contactPage-MainSection-privacypolicyText">
                  <a
                    href="/privacy"
                    target="_blank"
                    className="contactPage-MainSection-privacypolicyLink"
                  >
                    プライバシーポリシー
                  </a>
                  に同意する
                </p>
                <label className="contactPage-MainSection-acceptLabel">
                  <input
                    type="checkbox"
                    name="accept"
                    label=""
                    ref={register({ required: true })}
                    className="contactPage-MainSection-acceptCheck"
                  />
                  同意する
                </label>
                {errors.accept && (
                  <p className="contactPage-MainSection-errorMessage">
                    プライバシーポリシーへの同意は必須です。
                  </p>
                )}
              </div>
              <div className="contactPage-MainSection-buttonWrapper">
                <button
                  type="submit"
                  className="contactPage-MainSection-submitButton"
                >
                  入力内容を確認する
                </button>
              </div>
            </form>
          )}
        </div>
      </section>
    </Layout>
  )
}

const ConfirmView = props => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => navigate("/contact/thanks"))
      .catch(error => alert(error))
  }

  const onBack = () => {
    props.onBack()
  }

  return (
    <form name="contact" onSubmit={handleSubmit(onSubmit)} data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <input
        type="hidden"
        name="道新インタラクティブ株式会社コーポレートサイトへお問い合わせがありました。"
        value=""
      />
      <input
        type="hidden"
        ref={register}
        name="name"
        defaultValue={props.name}
      />
      <input
        type="hidden"
        ref={register}
        name="company"
        defaultValue={props.company}
      />
      <input
        type="hidden"
        ref={register}
        name="email"
        defaultValue={props.email}
      />
      <input type="hidden" ref={register} name="tel" defaultValue={props.tel} />
      <input
        type="hidden"
        ref={register}
        name="contents"
        defaultValue={props.contents}
      />
      <input
        type="hidden"
        ref={register}
        name="accept"
        defaultValue={props.accept}
      />

      <div className="contactPage-MainSection-formRow">
        <label className="contactPage-MainSection-formLabel" htmlFor="name">
          お名前
        </label>
        <div className="contactPage-MainSection-formInputWrapper">
          <p className="contactPage-MainSection-formInput" data-disabled="true">
            {props.name}
          </p>
        </div>
      </div>
      <div className="contactPage-MainSection-formRow">
        <label className="contactPage-MainSection-formLabel" htmlFor="company">
          会社名（団体名）
        </label>
        <div className="contactPage-MainSection-formInputWrapper">
          <p className="contactPage-MainSection-formInput" data-disabled="true">
            {props.company}
          </p>
        </div>
      </div>
      <div className="contactPage-MainSection-formRow">
        <label className="contactPage-MainSection-formLabel" htmlFor="email">
          メールアドレス
        </label>
        <div className="contactPage-MainSection-formInputWrapper">
          <p className="contactPage-MainSection-formInput" data-disabled="true">
            {props.email}
          </p>
        </div>
      </div>
      <div className="contactPage-MainSection-formRow">
        <label className="contactPage-MainSection-formLabel" htmlFor="tel">
          電話番号
        </label>
        <div className="contactPage-MainSection-formInputWrapper">
          <p className="contactPage-MainSection-formInput" data-disabled="true">
            {props.tel}
          </p>
        </div>
      </div>
      <div className="contactPage-MainSection-formRow">
        <label className="contactPage-MainSection-formLabel" htmlFor="contents">
          お問い合わせ内容
        </label>
        <div className="contactPage-MainSection-formInputWrapper">
          <p className="contactPage-MainSection-formInput" data-disabled="true">
            {props.contents}
          </p>
        </div>
      </div>
      <div className="contactPage-MainSection-buttonWrapper">
        <button onClick={onBack} className="contactPage-MainSection-backButton">
          修正する
        </button>
        <button type="submit" className="contactPage-MainSection-submitButton">
          送信する
        </button>
      </div>
    </form>
  )
}

export default ContactPage
